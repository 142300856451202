import { useMemo, useState } from "react";
import { fetchFactory } from "../../api";
import { REQUEST_METHODS } from "../../api/types";
import { API_ENDPOINT } from "../../constants";
import { useTranslationContext } from "../context";
import { Button, Icon } from "@hearsaycorp/hearsay-design-system";
var PROVIDER_TYPES;
(function (PROVIDER_TYPES) {
    PROVIDER_TYPES["SSO"] = "sso";
    PROVIDER_TYPES["FACEBOOK"] = "facebook";
    PROVIDER_TYPES["LINKEDIN"] = "linkedin";
    PROVIDER_TYPES["GOOGLE"] = "google";
})(PROVIDER_TYPES || (PROVIDER_TYPES = {}));
const ICON_MAPPINGS = {
    [PROVIDER_TYPES.FACEBOOK]: {
        name: Button.CONSTANTS.ICON.FACEBOOK,
        color: Icon.CONSTANTS.ICON_COLOR.FACEBOOK,
    },
    [PROVIDER_TYPES.LINKEDIN]: {
        name: Button.CONSTANTS.ICON.LINKEDIN,
        color: Icon.CONSTANTS.ICON_COLOR.LINKEDIN,
    },
    [PROVIDER_TYPES.GOOGLE]: {
        name: Button.CONSTANTS.ICON.GOOGLE,
        color: Icon.CONSTANTS.ICON_COLOR.GOOGLE,
    },
};
const SOCIAL_MEDIA_PROVIDER_TYPES = new Set([
    PROVIDER_TYPES.FACEBOOK,
    PROVIDER_TYPES.LINKEDIN,
    PROVIDER_TYPES.GOOGLE,
]);
const PROVIDER_ORDER = [
    PROVIDER_TYPES.SSO,
    PROVIDER_TYPES.FACEBOOK,
    PROVIDER_TYPES.LINKEDIN,
    PROVIDER_TYPES.GOOGLE,
];
const sortAuthProvider = (a, b) => PROVIDER_ORDER.indexOf(a.type) - PROVIDER_ORDER.indexOf(b.type);
const mapAuthProviderToClient = (labels) => (provider) => {
    var _a, _b;
    const type = SOCIAL_MEDIA_PROVIDER_TYPES.has(provider.type)
        ? provider.type
        : PROVIDER_TYPES.SSO;
    const displayedLabel = provider.displayName !== ""
        ? `${labels[type]} (${provider.displayName})`
        : labels[type];
    return {
        iconName: (_a = ICON_MAPPINGS[type]) === null || _a === void 0 ? void 0 : _a.name,
        iconColor: (_b = ICON_MAPPINGS[type]) === null || _b === void 0 ? void 0 : _b.color,
        label: displayedLabel,
        id: provider.id,
        type,
        url: provider.url,
        dataAttributes: { "data-metrics-tracking": `login_method_${type}` },
    };
};
const decode = (input) => new DOMParser().parseFromString(input, "text/html").body.textContent;
const useTransformProviders = (authProviders) => {
    const { LOG_IN_FACEBOOK, LOG_IN_LINKEDIN, LOG_IN_GOOGLE, LOG_IN_SSO } = useTranslationContext();
    const labels = useMemo(() => ({
        [PROVIDER_TYPES.SSO]: LOG_IN_SSO,
        [PROVIDER_TYPES.FACEBOOK]: LOG_IN_FACEBOOK,
        [PROVIDER_TYPES.LINKEDIN]: LOG_IN_LINKEDIN,
        [PROVIDER_TYPES.GOOGLE]: LOG_IN_GOOGLE,
    }), [LOG_IN_FACEBOOK, LOG_IN_LINKEDIN, LOG_IN_GOOGLE, LOG_IN_SSO]);
    const transformedProviders = useMemo(() => authProviders.map(mapAuthProviderToClient(labels)).sort(sortAuthProvider), [authProviders, labels]);
    return {
        transformedProviders,
    };
};
export const useAuthProviders = () => {
    const [loading, setLoading] = useState(false);
    const [authProviders, setAuthProviders] = useState([]);
    const [orgName, setOrgName] = useState("");
    const fetchAuthProviders = ({ orgId }) => {
        setLoading(true);
        return fetchFactory([REQUEST_METHODS.GET]).get(`${API_ENDPOINT}/org/${orgId}/providers/`, {
            success: (res) => {
                setAuthProviders(res.providers);
                setOrgName(decode(res.org_name));
                setLoading(false);
            },
            fail: () => {
                setLoading(false);
            },
        });
    };
    const { transformedProviders } = useTransformProviders(authProviders);
    return {
        authProviders: transformedProviders,
        loading,
        fetchAuthProviders,
        orgName,
    };
};
