import { I18nClient } from "@hearsaycorp/web-translations";
const { t } = I18nClient;
export const getStrings = () => ({
    LANDING_TITLE: t("LANDING_TITLE", { defaultValue: "Welcome back" }),
    LANDING_SUBTITLE: t("LANDING_SUBTITLE", {
        defaultValue: "Log in to your account.",
    }),
    EMAIL: t("EMAIL", { defaultValue: "Email" }),
    EMAIL_PLACEHOLDER: t("EMAIL_PLACEHOLDER", {
        defaultValue: "Enter your Email",
    }),
    CONTINUE_LABEL: t("CONTINUE_LABEL", { defaultValue: "Continue" }),
    SIGN_UP_PROMPT: t("SIGN_UP_PROMPT", {
        defaultValue: `If you don't have an account, contact your admin to sign up`,
    }),
    TROUBLE_LOGGING_IN: t("TROUBLE_LOGGING_IN", {
        defaultValue: "Trouble logging in?",
    }),
    CONTACT_SUPPORT: t("CONTACT_SUPPORT", { defaultValue: "Contact support" }),
    GENERAL_QUESTIONS: t("GENERAL_QUESTIONS", {
        defaultValue: "General questions?",
    }),
    LEARN_MORE: t("LEARN_MORE", { defaultValue: "Learn more" }),
    PRIVACY_POLICY: t("PRIVACY_POLICY", { defaultValue: "Privacy Policy" }),
    LOG_IN_ORG_HEADING: (orgName) => t("LOG_IN_ORG_HEADING", {
        orgName,
        defaultValue: `Log in to {{orgName}}`,
    }),
    LOG_IN_SSO: t("LOG_IN_SSO", { defaultValue: "Log in with SSO" }),
    LOG_IN_FACEBOOK: t("LOG_IN_FACEBOOK", {
        defaultValue: "Log in with Facebook",
    }),
    LOG_IN_LINKEDIN: t("LOG_IN_LINKEDIN", {
        defaultValue: "Log in with LinkedIn",
    }),
    LOG_IN_GOOGLE: t("LOG_IN_GOOGLE", { defaultValue: "Log in with Google" }),
    BACK_BUTTON: t("BACK_BUTTON", { defaultValue: "Go back" }),
    EMAIL_SUCCESS: (email) => t("EMAIL_SUCCESS", {
        email,
        defaultValue: "<strong>Login instructions sent</strong> to {{email}}",
    }),
    GENERIC_FAILURE: t("GENERIC_FAILURE", {
        defaultValue: "<strong>Something went wrong.</strong> Try again and contact support if this keeps happening.",
    }),
    ERROR_CODE: t("ERROR_CODE", { defaultValue: "Error code:" }),
});
